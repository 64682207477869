import PropTypes from "prop-types";
import React from "react";
import { MDXProvider } from "@mdx-js/react"
import Header from "./header";
import MDXComponents from "./MDXComponents";
import { Helmet } from "react-helmet";

import SpodniClaim  from "../texty/spodni_claim.mdx"

function Layout({ children }) {
  return (
    <MDXProvider
      components={MDXComponents}>
     {/*font customation, see also tailwind.config.js 
        https://tailwindcss.com/docs/font-family#customizing
        https://fonts.google.com/specimen/Poppins
      */}
    <Helmet>
        <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400" rel="stylesheet"></link>
    </Helmet>
    <div className="flex flex-col min-h-screen font-poppins font-thin text-gray-900">
      <Header />

      <main className="flex-1 w-full max-w-4xl px-4 py-8 mx-auto md:px-8 md:py-16">
        {children}
      </main>

      <footer className="bg-white text-grey-50">
        <nav className="flex justify-between max-w-4xl p-4 mx-auto text-sm md:p-8">
          <SpodniClaim/>
        </nav>
      </footer>
    </div>
    </MDXProvider>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
