import React from "react";
import { Link } from "gatsby";
import HorniClaim  from "../texty/horni_claim.mdx"
import MobileNav from "./MobileNav";

function Header() {
  const navLinks = [
    {
      route: `/`,
      title: `O MNĚ`,
    },
    /*{
      route: `/projects`,
      title: `PROJEKTY`,
    },*/
    {
      route: `/contact`,
      title: `KONTAKT`,
    },
  ];


  return (
    <header className="bg-white text-gray-900 ">
      <div className="flex flex-wrap items-center justify-between max-w-4xl p-4 mx-auto md:p-8">
        <Link href="/">
          <p className="flex items-center  no-underline">
          <HorniClaim/> 
          </p>
        </Link>

        <MobileNav className="float-right"navLinks={navLinks} />
        <nav className="hidden md:block md:items-center w-full md:w-auto" >
          {navLinks.map((link) => (
            <div key={link.title} className="inline  px-1 " >
            <Link
              key={link.title}
              to={link.route}
              activeClassName="font-bold"
            >
              {link.title}
            </Link>
            </div>
          ))}
        </nav>
      </div>

    </header>
  );
}

export default Header;
