import React from "react";
import { Link } from "gatsby";
import LinkedinIcon from "./linkedin";
import Blobik2 from "../images/blobik2.svg";


const CustomLink = (props) => {
  const href = props.href;
  const isInternalLink = href && (href.startsWith('/') || href.startsWith('#'));

  if (isInternalLink) {
    return (
      <Link href={href}>
        <a {...props} />
      </Link>
    );
  }

  return <a target="_blank" rel="noopener noreferrer" {...props} />;
};


const MDXComponents = {
//  Image,
  a: CustomLink,
  h1: (props) => <h1 class="text-xl space-y-5" {...props} />,
  h2: (props) => <h2 class="text-1g" {...props} />,      
  p: (props) =>  <p class="" {...props} />,
  LinkedinIcon,
  Blobik2,
};


export default MDXComponents;
